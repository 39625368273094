@tailwind base;
@tailwind components;
@tailwind utilities;

.sortable-chosen {
  box-shadow: 8px 8px 32px #e1e1e1;
}

.sortable-drag {
  opacity: 0;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
