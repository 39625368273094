/* SearchBox.css */

.search-box-container {
  position: relative;
}

.suggestions-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #cccccc;
}

.suggestion img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin-right: 8px;
}

.suggestion span {
  font-size: 14px;
}

/* Optional: You can add hover or active styles for suggestions here */
